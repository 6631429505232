import * as React from "react";
import {useEffect, useState} from "react";
import OrderOverview from "../components/OrderOverview";
import IDealPayment from "../components/IDealPayment";
import {Accordion} from "react-bootstrap";
import {ajaxUrl} from "../config";
import {browserHistory} from "../App";
import Breadcrumbs from "../components/Breadcrumbs";

/**
 * The page which comes after the user has filled in their order details. This displays the sisow frontend components.
 * @param props The properties passed along to this component
 * @returns {*} Jsx which will be rendered.
 */
export default function PaymentInitPage(props) {
    const [disabled, setDisabled] = useState(false);

    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
        });
    }, []);

    const startPayment = async function (method, issuer_id = null) {
        setDisabled(true);
        let url = ajaxUrl + "/payment/initiate";
        var data = new FormData();
        data.append('handle', props.location.state.id);
        data.append('year', props.location.state.year);
        data.append('week', props.location.state.week);
        data.append('day', props.location.state.weekDay);
        data.append('hour', props.location.state.startTime);
        data.append('name', props.location.state.name);
        data.append('address', props.location.state.address);
        data.append('email', props.location.state.email);
        data.append('phone', props.location.state.phone);
        data.append('residence', props.location.state.residence);
        switch (method) {
            case('ideal'):
                data.append('paymentMethod', "ideal");
                data.append('issuerId', issuer_id);
                break;
            case('creditcard'):
                data.append('paymentMethod', "creditcard");
                break;
            default:
                return;
        }

        await fetch(url, {
            method: "POST",
            body: data

        })
            .then(response => response.json())
            .then(response => window.open(response.url))
            .catch(() => browserHistory.push("/"));
        browserHistory.push("/payment/started");
    };
    return (
        <>
            <Breadcrumbs current={3} />
            <div id={"PaymentInitPage"} className={"opacity_show_animation flex-column d-flex"}>
                <div>
                    <header id={"header"} className={"text-center"}>
                        <h3>Jouw bestelling</h3>
                    </header>
                    <OrderOverview {...props}/>
                    <section id={"paymentSection"} className={"d-flex align-items-center flex-column"}>
                        <h4 className={"text-center"}>Kies een betaalmethode</h4>
                        <Accordion className={"col-md-6 mb-3"} defaultActiveKey="0">
                            <IDealPayment disabled={disabled} startPayment={startPayment}/>
                            {/*<CreditPayment disabled={disabled} startPayment={startPayment}/>*/}
                        </Accordion>
                    </section>
                </div>
            </div>
        </>
    );
}