import React from 'react';
import klantenvertellen from '../images/klantenvertellen.png';

/**
 * This creates the svg including the score
 * @param score The klantenvertellen rating
 * @returns {*}
 */
export default function KlantenVertellenScore({score}) {
    return (
        <>
            <svg style={{
                minWidth:"35px",
                minHeight:"35px",
                maxWidth:"35px",
                maxHeight:"35px",
                width:"35px",
                height:"35px"
            }}>
                <svg x="0px" y="0px" viewBox="0 0 100 100" xmlSpace="preserve">
                    <g>
                        <path className="st0"
                              d="M35.9,22.4L28.3,7.9C15.7,14.5,6.5,26.7,3.8,41.1l16.5,3C21.8,34.7,27.7,26.7,35.9,22.4z"/>
                        <path className="st1"
                              d="M20,48.8c0-1.6,0.1-3.1,0.4-4.6l-16.5-3c-0.5,2.6-0.7,5.3-0.8,8c0,3.8,0.4,7.5,1.2,11.1l16.7-3.8   C20.3,54,20,51.4,20,48.8z"/>
                        <path className="st2"
                              d="M20.9,56.4L4.2,60.2c1.2,5.1,3.2,9.9,5.9,14.3l14.8-9.2C23.1,62.6,21.8,59.6,20.9,56.4z"/>
                        <path className="st3"
                              d="M24.9,65.3l-14.8,9.2c2.2,3.5,4.8,6.7,7.8,9.6c-8.1,2.5-16.3,3.7-16.3,3.7c0.3,0.3,9.5,6.6,24,9.6l11.1-21.6   C32,73.3,27.9,69.7,24.9,65.3z"/>
                        <path className="st4"
                              d="M49.8,78.6c-4.7,0-9.1-1.1-13-3L25.7,97.3c7,1.5,15.3,2.1,24.5,1V78.6C50,78.6,49.9,78.6,49.8,78.6z"/>
                        <path className="st5"
                              d="M50.1,78.6v19.7c4.3-0.5,8.7-1.5,13.3-2.9l0,0c4.1-1.2,7.9-3,11.4-5.2l-9.7-15.8C60.8,77,55.7,78.6,50.1,78.6z   "/>
                        <path className="st6"
                              d="M79.6,48.8c0,10.8-5.8,20.3-14.4,25.5l9.7,15.8c6.7-4.2,12.3-10.1,16.2-17.1l0,0c3.8-6.8,6-14.6,6.1-22.8   c0-3.6-0.3-7.1-1-10.5l-17.1,3.7C79.4,45.2,79.6,47,79.6,48.8z"/>
                        <path className="st7"
                              d="M50.7,2.6C42.6,2.5,35,4.4,28.3,7.9l7.6,14.5c4.1-2.2,8.9-3.4,13.9-3.4c14.6,0,26.8,10.5,29.3,24.4l17.1-3.7   C91.7,18.7,73.1,2.9,50.7,2.6z"/>
                    </g>
                </svg>
                <text x="50%" y="55%" fontSize="12px" dominantBaseline="middle"
                      textAnchor="middle">{score / 10}</text>
            </svg>
            <img
                style={{
                    height: 35
                }}
                alt={"klantenvertellen"}
                src={klantenvertellen}
                className={"ml-2"}
            />
        </>
    );
}