import * as React from "react";
import {useEffect, useState} from "react";
import {Form, Card, Button} from "react-bootstrap";
import OrderOverview from "../components/OrderOverview";
import {browserHistory} from "../App";
import {Link} from "react-router-dom";
import {discountPrice, normalPrice} from "../config";
import Breadcrumbs from "../components/Breadcrumbs";

/**
 * The page where the user can place their order.
 * @param props The properties passed along to this component
 * @returns {*} Jsx which will be rendered.
 */
export default function OrderPage(props) {
    const [name, setName] = useState("");
    const [address, setAddress] = useState("");
    const [residence, setResidence] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");


    let first, firstOption;
    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
        });
    }, []);

    first = props.location.state.first;
    firstOption = props.location.state.firstOption;

    const done = function (event) {
        event.preventDefault();
        browserHistory.push("/pay", {
            id: props.location.state.id,
            school: props.location.state.school,
            year: props.location.state.year,
            week: props.location.state.week,
            weekDay: props.location.state.weekDay,
            hour: props.location.state.hour,
            month: props.location.state.month,
            monthDay: props.location.state.monthDay,
            startTime: props.location.state.startTime,
            endTime: props.location.state.endTime,
            name: name,
            address: address,
            residence: residence,
            email: email,
            phone: phone,
            first: props.location.state.first
        });
    };

    return (
        <>
            <Breadcrumbs current={2}/>
            <div id="OrderPage" className={"justify-content-center opacity_show_animation"}>
                <div className={"col-lg-6 col-md-8"}>
                    <header>
                        <h3 className={"text-center"}>Bevestig en betaal jouw proefles</h3>
                    </header>
                    <OrderOverview {...props} />
                    {!first && (
                        <div className={"d-flex justify-content-center"}>
                            <div className={"d-flex mb-4 card"} style={{width: "fit-content"}}>
                                <Card.Body>
                                    <div className={"text-center"}>
                                        Boek alsnog met 10% korting op<br/>
                                        {firstOption.state.formattedDate} <br/>
                                        <span>
                                        <s className={"text-danger"}>{normalPrice}</s> {discountPrice}
                                    </span>
                                    </div>
                                    <div className={"w-100 justify-content-center d-flex"}>
                                        <Button variant={"dation-alt mt-2"} as={Link} to={firstOption}>
                                            Boek direct
                                        </Button>
                                    </div>
                                </Card.Body>
                            </div>
                        </div>
                    )}
                    <section id={"formSection"} className={"mt-3"}>
                        <Form onSubmit={done} action="/">
                            <Form.Group>
                                <Form.Control required type="text" placeholder="Jouw naam" name="name"
                                              onChange={(x) => setName(x.target.value)}/>
                            </Form.Group>
                            <Form.Group>
                                <Form.Control required type="text" placeholder="Adres" name="address"
                                              onChange={(x) => setAddress(x.target.value)}/>
                            </Form.Group>
                            <Form.Group>
                                <Form.Control required type="text" placeholder="Woonplaats" name="residence"
                                              onChange={(x) => setResidence(x.target.value)}/>
                            </Form.Group>
                            <Form.Group>
                                <Form.Control required type="email" placeholder="Email" name="email"
                                              onChange={(x) => setEmail(x.target.value)}/>
                            </Form.Group>
                            <Form.Group>
                                <Form.Control required type="tel" placeholder="Telefoonnummer" name="phone"
                                              pattern="((\+31|0|0031)6){1}[1-9]{1}[0-9]{7}"
                                              onChange={(x) => {
                                                  setPhone(x.target.value)
                                                  if (x.target.validity.patternMismatch) {
                                                      if (!/^(\+31|0|0031)6/.test(x.target.value)) {
                                                          x.target.setCustomValidity("Begin je telefoonnummer met 06, +316 of 00316");
                                                      } else if (x.target.value.length < 10 || /((\+31|0|0031)6){1}[1-9]{1}[0-9]*/.test(x.target.value)) {
                                                          x.target.setCustomValidity("Controleer de lengte van je telefoonnummer");
                                                      } else if (/^((\+31|0|0031)6){1}0/.test(x.target.value)) {
                                                          x.target.setCustomValidity("Het eerste cijfer na het voorvoegsel mag geen 0 zijn")
                                                      } else {
                                                          x.target.setCustomValidity("Controleer de tekens in je telefoonnummer");
                                                      }
                                                  } else {
                                                      x.target.setCustomValidity("");
                                                  }
                                              }}
                                />
                            </Form.Group>
                            <Form.Group controlId={"formCheckbox"}>
                                <Form.Check required type="checkbox"
                                            label={<p>Ja, ik ga akkoord met de <a target="_blank"
                                                                                  rel="noopener noreferrer"
                                                                                  href="/algemene-voorwaarden.pdf">algemene
                                                voorwaarden </a> van Rijlesplanner</p>}/>
                            </Form.Group>
                            <Form.Group controlId={"formSubmit"} className={"d-flex justify-content-center"}>
                                <Button type="submit" variant="dation" size="lg">Naar betalen</Button>
                            </Form.Group>
                        </Form>
                    </section>
                </div>
            </div>
        </>
    );
}