import React from 'react';
import './App.css';
import MainPage from "./pages/MainPage";
import SuggestionsPage from "./pages/SuggestionsPage";
import {Route, Router, Switch} from "react-router-dom";
import CalendarPage from "./pages/CalendarPage";
import OrderPage from "./pages/OrderPage";
import PaymentInitPage from "./pages/PaymentInitPage";
import PaymentSuccessPage from "./pages/PaymentSuccessPage";
import PaymentCanceledPage from "./pages/PaymentCanceledPage";
import {createBrowserHistory} from "history";
import PaymentStartedPage from "./pages/PaymentStartedPage";

export const browserHistory = createBrowserHistory();

function App() {
    return (
        <Router history={browserHistory}>
            <Switch>
                <Route path="/proeflessen/:city" component={SuggestionsPage}/>
                <Route path="/calendar" component={CalendarPage}/>
                <Route path="/order" component={OrderPage}/>
                <Route path="/pay" component={PaymentInitPage}/>
                <Route path="/payment/started" component={PaymentStartedPage}/>
                <Route path="/payment/success" component={PaymentSuccessPage}/>
                <Route path="/payment/canceled" component={PaymentCanceledPage}/>
                <Route path="/rijschool/:handle" component={CalendarPage}/>
                <Route path="/" component={MainPage} />
            </Switch>
        </Router>
    );
}

export default App;