import React from "react";

/**
 * The page to which the user is redirected when the payment has failed.
 * @returns {*} Jsx which will be rendered.
 */
export default function PaymentCanceledPage() {
    return (
        <div className={"d-flex vh-100 align-items-center justify-content-center text-center"}>
            <div className={"col-md-4 my-auto"}>
                <h1>Betaling geannuleerd</h1>
                <h5>De betaling is geannuleerd, er is geen rijles geboekt </h5>
            </div>
        </div>
    );
}