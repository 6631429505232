import {useEffect, useMemo, useState} from "react";
import {ajaxUrl} from "../config";
import {browserHistory} from "../App";

export const useDrivingSchoolSuggestions = (city, filters, sorted, ascending) => {
    const [rawData, setRawData] = useState([]);
    const [latLong, setLatLong] = useState(null);

    useEffect(() => {
        let url = new URL(`${ajaxUrl}/google/geocode`);
        url.searchParams.append('address', city);
        fetch(url)
            .then(response => response.json())
            .then(result => {
                let lat = result.results[0].geometry.location.lat;
                let lng = result.results[0].geometry.location.lng;
                setLatLong({lat: lat, lng: lng})
            })
            .catch((error) => {
                browserHistory.push("/");
            });
    }, [city]);

    useEffect(() => {
        if (latLong) {
            let url = new URL(`${ajaxUrl}/nearby-driving-schools`);
            url.searchParams.append('lat', latLong.lat);
            url.searchParams.append('long', latLong.lng);
            url.searchParams.append('n', "15");
            for (let filter in filters) {
                url.searchParams.append(filters[filter], "true");
            }
            let storedData;
            if ((storedData = window.sessionStorage.getItem(url.toString())) === null) {
                fetch(url.toString())
                    .then(response => response.json())
                    .then(result => {
                        window.sessionStorage.setItem(url.toString(), JSON.stringify(result));
                        setRawData(result);
                    })
                    .catch(error => {
                        browserHistory.push("/");
                    });
            } else {
                setRawData(JSON.parse(storedData));
            }
        }
    }, [latLong, filters]);

    const data = useMemo(() => {
        if (sorted) {
            const copy = [...rawData];
            copy.sort((a, b) => {
                if (a[sorted.value] < b[sorted.value]) {
                    return -1;
                } else if (a[sorted.value] === b[sorted.value]) {
                    return 0;
                } else {
                    return 1;
                }
            })
            return ascending ? copy.reverse() : copy;
        } else {
            return rawData;
        }
    }, [rawData, ascending, sorted])

    return {data: data}
}