import React, {useEffect, useState} from "react";
import Select from "react-select";
import {css} from "emotion";
import {Form, Card, Collapse} from "react-bootstrap";
import getWindowWidth from "./WindowWidth";
import WindowSizeListener from "react-window-size-listener";

const SortFilter = ({setFilters, setSorted, ascending, setAscending}) => {
    const [windowWidth, setWindowWidth] = useState(getWindowWidth());
    const [showFilters, setShowFilters] = useState(getWindowWidth() >= 992);

    const filterCheckboxes = [
        {
            label: "Altijd dezelfde instructeur",
            value: "sameInstructor"
        },
        {
            label: "Automaat",
            value: "automaticShift"
        },
        {
            label: "Engelstalige instructeur",
            value: "englishInstructor"
        },
        {
            label: "Gespecialiseerd in faalangst",
            value: "performanceAnxiety"
        },
        {
            label: "Gespecialiseerd in autisme / ADHD",
            value: "autism"
        },
        {
            label: "BOVAG rijschool",
            value: "bovag"
        },
    ]
    const sortingOptions = [
        {
            label: "Google beoordeling",
            value: "google_score",
        },
        {
            label: "Slagingspercentage",
            value: "success_rate",
        },
        {
            label: "Klantenvertellen score",
            value: "kv_score",
        },
    ];

    useEffect(() => {
        if (windowWidth >= 992) setShowFilters(true);
    }, [windowWidth])

    return (
        <WindowSizeListener onResize={() => setWindowWidth(getWindowWidth())}>
            <Card className={"mt-4 sticky-top " + css`
                                    background: #e9ecef;
                                    top: 25px;
                                    border-radius: 0.5rem;
                                    z-index: 0;
                                    border: none;
                                    transition: all 0.35s ease;
                                    width: 100%;
                                    ${
                showFilters
                    ? ""
                    : "background: #0376bc;" +
                    "color: white;"
            }
                            `}>
                <Card.Title
                    className={`d-flex justify-content-center py-3 mb-0 ${showFilters ? "text-dation" : ""}`}
                    onClick={() => windowWidth < 992 && setShowFilters(!showFilters)}
                >
                    Filters
                </Card.Title>
                <Collapse id={"collapseFilters"} in={showFilters}>
                    <div>
                        <Card.Body className={"d-md-block text-dation"}>
                            <span className={"font-weight-bold"}>Sorteer op</span>
                            <div className={"d-flex"}>
                                <div
                                    className={
                                        "d-flex align-items-center justify-content-center "
                                        + css`
                            border-radius: 4px;
                            border-style: solid;
                            border-width: 1px;
                            border-color: hsl(0, 0%, 80%);
                            background-color: hsl(0, 0%, 100%);
                            height: 38px;
                            width: 38px;
                            `
                                    }
                                    onClick={() => setAscending((oldAscending) => !oldAscending)}
                                >
                                    <i className={`material-icons unselectable ${css`
                            transition: transform 0.2s;
                            cursor: pointer;
                            ${ascending ? "transform: rotate(180deg)" : ""}
                        `}`}>
                                        arrow_upward
                                    </i>
                                </div>
                                <div className={"flex-fill"}>
                                    <Select
                                        options={sortingOptions}
                                        onChange={value => setSorted(value || "")}
                                        placeholder={"Sorteren"}
                                    />
                                </div>
                            </div>
                            <div className={"mt-3"}>
                                <span className={"font-weight-bold"}>
                                Opties
                                </span>
                                {
                                    filterCheckboxes.map(item =>
                                        <Form.Group controlId={`checkBox${item.label}`}>
                                            <Form.Check style={{userSelect: "none"}} label={item.label} key={item.label} onChange={e => {
                                                e.target.checked
                                                    ? setFilters((oldFilters) => oldFilters.concat(item.value))
                                                    : setFilters((oldFilter) => oldFilter.filter(i => i !== item.value));
                                            }}
                                            />
                                        </Form.Group>
                                    )
                                }
                            </div>
                        </Card.Body>
                    </div>
                </Collapse>
            </Card>
        </WindowSizeListener>
    );
}

export default SortFilter;