import {getDateOfISOWeek} from "./WeekDate";
import * as React from "react";
import {Button, Card, Row} from "react-bootstrap";
import {Link} from "react-router-dom";
import {discountPrice, normalPrice} from "../config";
import KlantenVertellenScore from "./KlantenVertellenScore";
import SlagingsPercentage from "./SlagingsPercentage";
import Col from "react-bootstrap/Col";
import GoogleScore from "./GoogleScore";
import Popup from "reactjs-popup";
import WindowSizeListener from "react-window-size-listener";
import {useState} from "react";
import {css} from "emotion";
import ShowMoreText from 'react-show-more-text';
import getWindowWidth from "./WindowWidth";

/**
 * This component renders each single driving school.
 * @param props The component's properties, passed along by its parents.
 * @returns {*} Jsx which will be rendered.
 */
export default function DrivingSchool(props) {
    let id = props.id;
    let name = props.name;
    let description = props.description;
    let year = props.year;
    let week = props.week;
    let weekDay = props.day;
    let startTime = props.hour;
    let endTime = startTime + 1;
    let kvScore = props.kvScore;
    let successRate = props.successRate;
    let googleScore = props.googleScore;
    let location = props.location;

    const [windowWidth, setWindowWidth] = useState(getWindowWidth());

    let date = getDateOfISOWeek(week, year, weekDay);
    let month = date.getMonth();
    let monthDay = new Intl.DateTimeFormat("nl-NL", {day: "numeric"}).format(date);
    let formattedDate = new Intl.DateTimeFormat("nl-NL",
        {weekday: "long", year: "numeric", month: "long", day: "numeric"})
        .format(date) + ` ${startTime}:00 - ${endTime}:00`;

    let firstOptionTo = {
        pathname: `/order`,
        state: {
            id: id,
            school: name,
            year: year,
            week: week,
            month: month,
            monthDay: monthDay,
            weekDay: weekDay,
            startTime: startTime,
            endTime: endTime,
            first: true,
            formattedDate: formattedDate
        }
    };

    return (
        <WindowSizeListener onResize={() => setWindowWidth(getWindowWidth())}>
            <div className={"mt-4"}>
                <Card id={"DrivingSchool" + id} className={"mb-1 d-flex flex-column " + css`
                background: #e9ecef;
                border-radius: 0.5rem;
                border: none;
            `}>
                    <Card.Body>
                        <Row>
                            <Col xs={12} lg={5} className={"py-2"}>
                                <Row>
                                    <Col>
                                        <Row>
                                            <Col xs={1}/>
                                            <Col>
                                                <Row className={"justify-content-center justify-content-lg-start"}>
                                                    <h4>{name}</h4>
                                                </Row>
                                                {
                                                    location &&
                                                    <Row className={"justify-content-center justify-content-lg-start"}>
                                                        <h6 className={"text-muted"}><i
                                                            className={"material-icons h6"}>location_on</i>{location}
                                                        </h6>
                                                    </Row>
                                                }
                                            </Col>
                                            <Col xs={1} className={"p-0"}>
                                                {
                                                    description && windowWidth < 1200 &&
                                                    <Popup
                                                        trigger={
                                                            <span
                                                                className={"material-icons text-dation unselectable " + css`
                                                                z-index: 100; 
                                                                font-size: 28px;
                                                                `}>info</span>
                                                        }
                                                        position={['right center', 'left center', 'bottom center', 'bottom left', 'bottom right']}
                                                        closeOnDocumentClick
                                                        keepTooltipInside={"#suggestion-section-2"}
                                                        modal={true}
                                                        repositionOnResize={true}
                                                        contentStyle={
                                                            windowWidth < 1200 && ({
                                                                margin: "auto 20px auto 20px",
                                                                padding: "0.75rem",
                                                                zIndex: "1000",
                                                            })
                                                        }
                                                    >
                                                        <div>
                                                            {
                                                                description.split('\n').map((content, index) => <p key={index}>{content}</p>)
                                                            }
                                                        </div>
                                                    </Popup>
                                                }

                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row className={"justify-content-center"}>
                                    {
                                        googleScore ?
                                            <Col xs={12} md={6} className={'d-flex justify-content-center my-4'}>
                                                <GoogleScore score={googleScore}/>
                                            </Col>
                                            : ''
                                    }
                                    {
                                        kvScore ?
                                            <Col xs={12} md={6} className={'d-flex justify-content-center my-4'}>
                                                <KlantenVertellenScore score={kvScore}/>
                                            </Col>
                                            : ''
                                    }
                                    {
                                        successRate ?
                                            <Col xs={12} md={6} className={'d-flex justify-content-center my-4'}>
                                                <SlagingsPercentage percentage={successRate}/>
                                            </Col>
                                            : ''
                                    }
                                </Row>
                            </Col>
                            {
                                description && windowWidth >= 1200 &&
                                <Col xs={4}>
                                    <h6>Over {name}</h6>
                                    <ShowMoreText
                                        lines={8}
                                        more={"Toon meer"}
                                        less={"Toon minder"}
                                        expanded={false}
                                    >
                                        {description.split('\n').map((content, index) => <p key={index}>{content}</p>)}
                                    </ShowMoreText>
                                </Col>
                            }
                            <Col xs={12} lg={description && windowWidth >= 1200 ? 3 : 7}>
                                <div className={"d-flex flex-column align-items-center justify-content-center mt-3"}>
                                    <div className={"text-center"}>
                                        Boek direct met 10% korting op {formattedDate}<br/>
                                        <span>
                                        <s className={"text-danger"}>{normalPrice}</s> {discountPrice}
                                    </span>
                                    </div>
                                    <Button variant={"dation-alt mt-2"} as={Link} to={firstOptionTo}>
                                        Boek direct
                                    </Button>
                                </div>
                                <hr/>
                                <div className={"d-flex justify-content-center"}>
                                    <p className={"text-muted font-italic text-center"}>
                                        Andere datum bij {name}?
                                    </p>
                                </div>
                                <div className={"d-flex justify-content-center"}>
                                    <Button variant={"dation"} className={"d-flex"} as={Link} to={{
                                        pathname: `/calendar`,
                                        state: {
                                            id: id,
                                            drivingSchool: name,
                                            firstOption: firstOptionTo
                                        }
                                    }}>
                                        <i className={"material-icons"}>today</i><span className={"mx-1"}/>Bekijk agenda
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            </div>
        </WindowSizeListener>
    );
}