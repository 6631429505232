import * as React from "react";
import {useEffect, useState} from "react";
import {ajaxUrl, numberOfFreeMoments} from "../config";
import "../styles/CalendarPage.scss";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import CarLoader from "../components/CarLoader";
import {Link} from "react-router-dom";
import {getDateOfISOWeek} from "../components/WeekDate";
import {browserHistory} from "../App";
import Breadcrumbs from "../components/Breadcrumbs";
import Container from "react-bootstrap/Container";
import { useParams, useHistory } from "react-router-dom";

/**
 * The main method, which will render everything on this page by making use of the other functions.
 * @param props The component's properties, passed along by its parents.
 * @returns {*} Jsx which will be rendered.
 */
export default function CalendarPage(props) {
    const { handle } = useParams();

    const [data, setData] = useState([]);
    const [drivingSchool, setDrivingSchool] = useState("");
    const [id, setId] = useState("");

    const [notFound, setNotFound] = useState(false);

    const [firstOption, setFirstOption] = useState(props.location.state ? props.location.state.firstOption || [] : []);
    const [loader, setLoader] = useState(true);
    const [animation, setAnimation] = useState(true);


    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
        });

        if (handle) {
            window.sessionStorage.clear();
            setId(handle);
            fetch(`${ajaxUrl}/school/${handle}`)
                .then(response => response.json())
                .then(result => setDrivingSchool(result.name))
                .catch(() => setNotFound(true))
            ;
        } else {
            if (props.location.state && props.location.state.drivingSchool && props.location.state.id) {
                setDrivingSchool(props.location.state.drivingSchool);
                setId(props.location.state.id);
            } else {
                browserHistory.push("/");
            }
        }
    }, [handle, props.location.state]);

    useEffect(() => {
        if (id) {
            getTimeSlots(id, {setData, setLoader})
        }
    }, [id]);

    useEffect(() => {
        if (!loader) {
            setTimeout(() => {
                setAnimation(false);
            }, 300);
        }
    }, [loader]);


    if (notFound) return (
        <div className={"w-100 vh-100 d-flex flex-column justify-content-center align-items-center"}>
            <h1>De gegeven rijschool kon niet gevonden worden.</h1>
            <Button variant={"dation-alt"} as={Link} to={"/"}>Klik hier om terug te gaan</Button>
        </div>
    );
    return (
        <>
            <Breadcrumbs current={1}/>
            <div id={"CalendarPage"} className={"opacity_show_animation"}>
                <div className={"w-100"}>
                    <section id="year-section-2"
                             className={(animation ? "d-none visibly_hidden" : "d-flex opacity_show_animation") + " container text-center flex-column"}>
                        <Container>
                            <Row>
                                <Col xs={12} sm={4}>
                                    {
                                        !handle &&
                                        <Button size={"lg"} variant={"outline-dation d-flex mr-auto mb-3"} onClick={() => browserHistory.goBack()}>
                                            <i className={"material-icons"}>arrow_back</i>
                                        </Button>
                                    }
                                </Col>
                                <Col>
                                    <h2>{drivingSchool}</h2>
                                </Col>
                                <Col xs={12} sm={4} />
                            </Row>
                        </Container>
                            <Years yeardata={data} firstOption={firstOption} setFirstOption={setFirstOption} id={id} drivingSchool={drivingSchool} {...props}/>
                    </section>
                </div>
                <div className={"w-100 position-absolute"}>
                    <CarLoader disable={!loader}/>
                </div>
            </div>
        </>
    );
}

/**
 * Performs an ajax request to the backend to retrieve the free timeslots for a given driving school.
 * @param id The url parameter which is unique per driving school, used as an identifier.
 * @param setFunctions The list of functions that contains the relevant setState functions.
 */
const getTimeSlots = (id, setFunctions) => {
    let url = `${ajaxUrl}/school/${id}/calendar?n=${numberOfFreeMoments}`;
    let storedData;
    if ((storedData = window.sessionStorage.getItem(url)) === null) {
        fetch(url)
            .then(response => response.json())
            .then(result => {
                window.sessionStorage.setItem(url, JSON.stringify(result));
                setFunctions.setData(result);
                setFunctions.setLoader(false);
            });
    } else {
        let result = JSON.parse(storedData)
        setFunctions.setData(result);
        setFunctions.setLoader(false);
    }
}

/**
 * This method parses the json data in an element which contains the key in the `elem` field and the value in the
 * `child` field, this is used in all the Components that display parts of this data.
 * @param jsonData The data which has to be parsed.
 * @returns info which is an array containing items which each have an elem and a child.
 */
function getInformation(jsonData) {
    let info = [];
    let elem;
    for (elem in jsonData) {
        info.push({
            elem: elem,
            child: jsonData[elem]
        });
    }
    return info;
}

/**
 * The method responsible for rendering the years including its children.
 * @param props The component's properties, passed along by its parents.
 * @returns {*} Jsx which will be rendered.
 */
function Years(props) {
    const [years, setYears] = useState([]);

    useEffect(() => {
        setYears(getInformation(props.yeardata));
    }, [props.yeardata]);

    return (
        <div className={"w-100"}>
            {years.map((year, index) =>
                <div className={"w-100"} key={"Year-" + year.elem} id={"Year-" + year.elem}>
                    <Weeks year={year.elem} weekdata={year.child} {...props} index={index}/>
                </div>
            )}
        </div>
    );
}

/**
 * The method responsible for rendering the weeks including its children.
 * @param props The component's properties, passed along by its parents.
 * @returns {*} Jsx which will be rendered.
 */
function Weeks(props) {
    let year = props.year;

    const [weeks, setWeeks] = useState([]);

    useEffect(() => {
        setWeeks(getInformation(props.weekdata));
    }, [props.weekdata]);

    let parseDate = (week) => {
        let date = getDateOfISOWeek(week, year, 1);
        let date2 = getDateOfISOWeek(week, year, 7);
        if (date.getMonth() === date2.getMonth()) {
            date = new Intl.DateTimeFormat("nl-NL", {day: 'numeric'}).format(date);
            date2 = new Intl.DateTimeFormat("nl-NL", {month: 'long', day: 'numeric'}).format(date2);
        } else {
            date = new Intl.DateTimeFormat("nl-NL", {month: 'long', day: 'numeric'}).format(date);
            date2 = new Intl.DateTimeFormat("nl-NL", {month: 'long', day: 'numeric'}).format(date2);
        }
        return date + " - " + date2;
    }

    return (
        <div className={"w-100"}>
            {weeks.map((week, index) =>
                <div className={"w-100"} key={"Week-" + week.elem} id={"Week-" + week.elem}>
                    <h5 className={"text-muted mb-3"}>Week {week.elem}, {parseDate(week.elem)}</h5>
                    <Days year={year} week={week.elem} daydata={week.child} {...props}
                          index={index * 24 + props.index}/>
                </div>
            )}
        </div>
    );
}

/**
 * The method responsible for rendering the days including its children.
 * @param props The component's properties, passed along by its parents.
 * @returns {*} Jsx which will be rendered.
 */
function Days(props) {
    let year = props.year;
    let week = props.week;

    const [days, setDays] = useState([]);

    useEffect(() => {
        setDays(getInformation(props.daydata));
    }, [props.daydata]);

    let parseDate = (day) => {
        let date = getDateOfISOWeek(week, year, day);
        return new Intl.DateTimeFormat("nl-NL", {weekday: 'long', day: 'numeric', month: 'long'}).format(date);
    }

    return (
        <div className={"w-100"}>
            {days.map((day, index) =>
                <div className={"w-100 mb-4"} key={"Day-" + day.elem} id={"Day-" + day.elem}>
                    <Row>
                        <Col md={2} lg={4} className={"text-capitalize"}>
                            {parseDate(day.elem)}
                        </Col>
                        <Col md={8} lg={4}>
                            <Times day={day.elem} timedata={day.child} {...props} index={index * 24 + props.index}/>
                        </Col>
                        <Col md={2} lg={4}/>
                    </Row>
                </div>
            )}
        </div>
    );
}

/**
 * The method responsible for rendering the times available for each day in each week in each year.
 * @param props The component's properties, passed along by its parents.
 * @returns {*} Jsx which will be rendered.
 */
function Times(props) {
    const [times, setTimes] = useState([]);

    useEffect(() => {
        setTimes(getInformation(props.timedata));
    }, [props.timedata]);

    return (
        <div>
            {times.map((time, index) =>
                <div className={"mb-2"} key={"Time-" + time.child} id={"Time-" + time.child}>
                    <Time time={time} {...props} index={index * 24 + props.index}/>
                </div>
            )}
        </div>
    );
}

/**
 * Renders the time button. If 'firstOption' is true, it will render a red button with discounted price.
 * @param props The component's properties, passed along by its parents.
 * @returns {*} Jsx which will be rendered.
 */
function Time(props) {
    let isFirstOption = props.index === 0;
    let date = getDateOfISOWeek(props.week, props.year, props.day);
    const history = useHistory();
    let to = {
        pathname: '/order',
        state: {
            id: props.id,
            week: props.week,
            school: props.drivingSchool,
            year: props.year,
            month: date.getMonth(),
            monthDay: new Intl.DateTimeFormat("nl-NL", {day: "numeric"}).format(date),
            weekDay: props.day,
            startTime: props.time.child,
            endTime: parseInt(props.time.child) + 1,
            first: isFirstOption,
            formattedDate: new Intl.DateTimeFormat("nl-NL",
                {weekday: "long", year: "numeric", month: "long", day: "numeric"})
                .format(date) + ` ${props.time.child}:00 - ${parseInt(props.time.child) + 1}:00`,
            firstOption: props.firstOption
        }
    };
    useEffect(() => {
        if (isFirstOption && props.firstOption.length === 0) {
            props.setFirstOption(to);
            history.replace({
                pathname: history.location.pathname,
                state: {
                    ...history.location.state,
                    firstOption: to
                }
            });
        }
    }, [props.firstOption, history, isFirstOption, props, to]);

    if (isFirstOption) {
        return (
            <Button className={"timebtn"} variant={"dation-alt"} block as={Link} to={to}>
                {props.time.child}:00 - {parseInt(props.time.child) + 1}:00
                <br/>
                (10% korting)
            </Button>
        );
    } else {
        return (
            <Button className={"timebtn"} variant={"dation"} block as={Link} to={to}>
                {props.time.child}:00 - {parseInt(props.time.child) + 1}:00
            </Button>
        );
    }
}