// Used for the google apis
export const googleKey = `${process.env.REACT_APP_GOOGLE_KEY}`;

// Used to send requests to the back-end
export const ajaxUrl = `${process.env.REACT_APP_BACKEND_URL}`;

// General pricing
export const discountPrice = "€31,50";
export const normalPrice = "€35,00";
export const discountPercentage = "10%";

// For SuggestionsPage.js
export const numberOfDrivingSchools = 3;
export const maxNumberOfDrivingSchools = numberOfDrivingSchools * 5;

// For CalendarPage.js
export const numberOfFreeMoments = 10;