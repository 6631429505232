import * as React from "react";
import {useEffect, useState} from "react";
import '../styles/custom.scss';
import '../styles/SuggestionsPage.scss';
import { maxNumberOfDrivingSchools, numberOfDrivingSchools} from "../config";
import CarLoader from "../components/CarLoader";
import DrivingSchool from "../components/DrivingSchool";
import {Button, Col, Container, Row} from "react-bootstrap";
import Breadcrumbs from "../components/Breadcrumbs";
import SortFilter from "../components/SortFilter";
import NoDrivingSchoolFound from "../components/NoDrivingSchoolFound";
import {useParams} from "react-router-dom";
import {useDrivingSchoolSuggestions} from "../hooks/useDrivingSchoolSuggestions";

/**
 * This component renders the entire suggestionspage.
 * @param props The component's properties, passed along by its parents.
 * @returns {*} Jsx which will be rendered.
 */
export default function SuggestionsPage(props) {
    const {city} = useParams();
    const [filters, setFilters] = useState([]);
    const [sorted, setSorted] = useState(null);
    const [ascending, setAscending] = useState(true);
    const {data} = useDrivingSchoolSuggestions(city, filters, sorted, ascending);
    const [showAmount, setShowAmount] = useState(numberOfDrivingSchools);
    const [loader, setLoader] = useState(true);
    const [animation, setAnimation] = useState(true);

    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
        });
    }, []);

    useEffect(() => {
        if (!loader) {
            setTimeout(() => {
                setAnimation(false);
            }, 300);
        }
    }, [loader]);

    useEffect(() => {
        if (data.length > 0) {
            setLoader(false);
        }
    }, [data])

    return (
        <>
            <Breadcrumbs current={1}/>
            <div id="SuggestionsPage" className="opacity_show_animation">
                <div id="suggestion-section-2"
                     className={"w-100 " + (animation ? "d-none visibly_hidden" : "d-flex opacity_show_animation")}>
                    <Container fluid={true} className={"w-100"}>
                        <Row className={"h-100"}>
                            <Col xs={12} lg={3}>
                                <SortFilter setFilters={setFilters} setSorted={setSorted} ascending={ascending} setAscending={setAscending}/>
                            </Col>
                            <Col>
                                <Row className={"justify-content-between"}>
                                    {
                                        data.length === 0 &&
                                        <Col xs={12} className={"mt-4 w-100"}>
                                            <NoDrivingSchoolFound />
                                        </Col>
                                    }
                                    {data.map((drivingSchool, index) => {
                                            if (index < showAmount) {
                                                return (
                                                    <Col xs={12} className={"w-100"} key={index}>
                                                        <DrivingSchool
                                                            id={drivingSchool.handle}
                                                            name={drivingSchool.name}
                                                            description={drivingSchool.description}
                                                            year={drivingSchool.year}
                                                            week={drivingSchool.week}
                                                            day={drivingSchool.day}
                                                            hour={drivingSchool.hour}
                                                            kvScore={drivingSchool.kv_score}
                                                            successRate={drivingSchool.success_rate}
                                                            googleScore={drivingSchool.google_score}
                                                            location={drivingSchool.place}
                                                        />
                                                    </Col>
                                                );
                                            } else {
                                                return null;
                                            }
                                        }
                                    )}
                                </Row>
                                <Row className={"justify-content-center mt-3"}>
                                    <Button variant={"dation-alt my-2"} size={"lg"} onClick={() => {
                                        setLoader(true);
                                        setTimeout(() => setShowAmount(showAmount + numberOfDrivingSchools), 10);
                                    }} hidden={showAmount >= maxNumberOfDrivingSchools || data.length === 0}>Toon meer</Button>
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <div className={"w-100 position-absolute"}>
                    <CarLoader disable={!loader}/>
                </div>
            </div>
        </>
    );
}