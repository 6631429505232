import React, {useEffect, useState} from 'react';
import {ajaxUrl} from "../config";
import {ListGroup, ListGroupItem} from "react-bootstrap";
import Card from "react-bootstrap/Card";
import Accordion from "react-bootstrap/Accordion";

export default function IDealPayment(props) {
    const [data, setData] = useState([]);

    useEffect(() => {
        async function fetchData() {
            let url = ajaxUrl + '/payment/providers';
            await fetch(url)
                .then(response => response.json())
                .then((response) => setData(response));
        }

        fetchData();
    }, []);

    const items = data.map((x) => (
        <ListGroupItem id={"list-group-item-" + x.id} role="button" key={x.id} className={"d-flex"} action
                       onClick={() => props.startPayment('ideal', x.id)} disabled={props.disabled ? 'disabled' : ''}>
            <h6 className={"mb-0"}>{x.name}</h6>
            <i className={"material-icons ml-auto "}>keyboard_arrow_right</i>
        </ListGroupItem>
    ));
    return (
        <Card>
            <Accordion.Toggle as={Card.Header} role="button" eventKey="0">
                <span className={"d-flex"}>iDEAL<i
                    className={"material-icons ml-auto arrow"}>keyboard_arrow_down</i></span>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="0">
                <Card.Body>
                    <ListGroup className={'px-0'} variant={"flush"}>{items}</ListGroup>
                </Card.Body>
            </Accordion.Collapse>
        </Card>
    );
}