import * as React from "react";
import LocationField from "../components/LocationField";
import '../styles/custom.scss';
import '../styles/MainPage.css';
import {useEffect} from "react";

/**
 * The main page of the app.
 * @returns {*} Jsx which will be rendered.
 */
export default function MainPage() {
    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
        });
    }, []);

    // Clear the session storage to make sure the user gets new data from the backend
    window.sessionStorage.clear();
    return (
        <div id="mainPage" className="container opacity_show_animation justify-content-center">
            <div className="row w-100 justify-content-center">
                <div className="col-lg-6 col-md-8">
                    <section id="section-3">
                        <LocationField/>
                    </section>
                </div>
            </div>
        </div>
    );
}