import React from "react";
import {css} from "emotion";
import {Card} from "react-bootstrap";

const NoDrivingSchoolFound = () => {
    return (
        <Card className={"mb-1 d-flex flex-column " + css`
                                            background: #e9ecef;
                                            border-radius: 0.5rem;
                                            border: none;
                                        `}>
            <Card.Body className={"text-center text-dation"}>
                <span className={"font-weight-bold"}>Helaas</span> kunnen wij geen rijscholen vinden die voldoen aan jouw zoekcriteria. <br />
                Pas je zoekcriteria aan om rijscholen bij jou in de buurt te vinden.
            </Card.Body>
        </Card>
    );
}

export default NoDrivingSchoolFound;