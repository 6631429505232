import * as React from "react";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import {browserHistory} from "../App";

/**
 * Used for the navigation at the top while ordering
 * @param props Used to retrieve the current page
 * @returns {*} Jsx which will be rendered.
 */
export default function Breadcrumbs(props) {
    let current = props.current ? props.current : 1;

    // Goes to page by using the difference between the current page and the page you want to go to
    const goToPage = (page) => {
        let diff;
        if ((diff = page - current) < 0)
            browserHistory.go(diff);
    }

    return (
        <Breadcrumb>
            <Breadcrumb.Item active={current <= 1}  href="#" onClick={() => goToPage(1)} className={current === 1 ? "font-weight-bold text-dation" : ""}>1. Bestelling</Breadcrumb.Item>
            <Breadcrumb.Item active={current <= 2}  href="#" onClick={() => goToPage(2)} className={current === 2 ? "font-weight-bold text-dation" : ""}>2. Gegevens</Breadcrumb.Item>
            <Breadcrumb.Item active={current <= 3}  href="#" onClick={() => goToPage(3)} className={current === 3 ? "font-weight-bold text-dation" : ""}>3. Betaling</Breadcrumb.Item>
        </Breadcrumb>
    );
}