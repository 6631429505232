import React from "react";
import googleReviews from '../images/Google-Reviews-transparent.png';

const GoogleScore = ({score}) => {
    return (
        <>
            <span className={"text-muted"}>{score / 10}/5</span>
            <img src={googleReviews}
                 alt={"Google reviews"}
                 style={{
                     height: 35
                 }}
                 className={"ml-2"}
            />
        </>
    );
}

export default GoogleScore;