import React, {useRef} from 'react';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import {ajaxUrl, googleKey} from "../config";
import {css} from "emotion";
import poweredByGoogle from "../images/powered_by_google_on_white_hdpi.png";
import CustomSelectComponent from "./CustomSelectComponent";

const getLocation = () => new Promise(resolve => {
    if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((position) => {
                let url = new URL(`${ajaxUrl}/google/geocode`);
                url.searchParams.append('latlng', position.coords.latitude + ',' + position.coords.longitude);
                fetch(url)
                    .then(response => response.json())
                    .then(result => {
                        resolve(result.results[0].address_components[2]["long_name"]);
                    });
            }
        );
    } else {
        alert("We kunnen je locatie helaas niet ophalen, vul hem handmatig in.");
        resolve(null);
    }
});

const GoogleAutocompleteInput = ({changeAddress, currentSelected}) => {
    const selectRef = useRef();

    return (
        <GooglePlacesAutocomplete
            apiKey={googleKey}
            autocompletionRequest={{
                componentRestrictions: {
                    country: ["nl"]
                },
                types: [
                    '(regions)'
                ]
            }}
            selectProps={{
                ref: selectRef,
                value: currentSelected,
                onChange: value => {
                    changeAddress(value);
                    selectRef.current.blur();
                },
                onFocus: () => changeAddress(null),
                components: {
                    Menu: ({children, ...props}) => CustomSelectComponent({
                        styleName: "menu",
                        children: (
                            <>
                                {children}
                                <div className={"w-100 d-flex justify-content-end"}>
                                    <img alt={"Powered by Google"} src={poweredByGoogle} className={"m-2 " + css`height: 16px;`}/>
                                </div>
                            </>
                        ),
                        ...props
                    }),
                    DropdownIndicator: () => <></>,
                    IndicatorSeparator: () => <></>,
                    NoOptionsMessage: ({...props}) => CustomSelectComponent({
                        replacementText: "Geen zoekresultaten",
                        styleName: "noOptionsMessage",
                        ...props
                    }),
                    LoadingMessage: ({...props}) => CustomSelectComponent({
                        replacementText: "Laden...",
                        styleName: "loadingMessage",
                        ...props
                    }),
                    Placeholder: ({...props}) => CustomSelectComponent({
                        replacementText: "Woonplaats",
                        styleName: "placeholder",
                        ...props
                    }),

                    ValueContainer: ({...props}) => CustomSelectComponent({
                        styleName: "valueContainer",
                        extraClasses: "justify-content-center",
                        ...props
                    }),
                    SelectContainer: ({...props}) => CustomSelectComponent({
                        styleName: "container",
                        extraClasses: "d-flex w-100",
                        ...props
                    }),
                    Control: ({...props}) => (
                        <>
                            {CustomSelectComponent({
                                styleName: "control",
                                extraClasses: `w-100 ${css`border-radius: 4px 0px 0px 4px;`}`,
                                ...props
                            })}
                            <div className={"d-flex justify-content-center align-items-center " + css`
                                            min-height: 38px;
                                            min-width: 38px;
                                            border-radius: 0px 4px 4px 0px;
                                            border-style: solid;
                                            border-width: 1px;
                                            border-color: hsl(0, 0%, 80%);;
                                            background: hsl(0, 0%, 90%);
                                            margin-left: -1px;
                                            &:hover {
                                                cursor: pointer;
                                            }
                                        `}
                                 onClick={async () => {
                                     let address = await getLocation();

                                     changeAddress({
                                         label: address
                                     });
                                 }}
                            >
                                <i className={"material-icons unselectable text-primary "}>place</i>
                            </div>
                        </>
                    ),
                },
            }}
        />
    );
}

export default GoogleAutocompleteInput;