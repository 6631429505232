import React, {useEffect, useState} from "react";
import {CircularProgressbarWithChildren} from "react-circular-progressbar";
import 'react-circular-progressbar/dist/styles.css';

export default function SlagingsPercentage({percentage=50}) {
    const [percent, setPercent] = useState(0);

    useEffect(() => {
        setPercent(0);
        setTimeout(() => setPercent(percentage), 500)
    }, [percentage]);

    return (
        <div className={"d-flex"}>
            <div style={{
                width: 35,
                height: 35,
                minWidth: 35,
                minHeight: 35
            }} className={"mr-1"}>
                <CircularProgressbarWithChildren value={percent}>
                    <div style={{fontSize: 10}} className={"text-center"}>{`${percent}%`}</div>
                </CircularProgressbarWithChildren>
            </div>
            <small className={"text-dation align-self-end"}>
                CBR Slagings&shy;percentage
            </small>
        </div>
    );
}