import moment from "moment";

/**
 * This method parses the parameters given to it into a readable date format.
 * @param w The weeknumber.
 * @param y The Year.
 * @param d The day of the week.
 * @returns string Formatted date string with the format dd-M, where M is the written-out month.
 */
export function getDateOfISOWeek(w, y, d) {
    let date = moment(`${y}-W${w}-${d}`);
    return date.toDate();
}