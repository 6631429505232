import React from "react";
import confetti from "canvas-confetti";
import {Animated} from "react-animated-css";

/**
 * The page to which the user is redirected when the payment has been successful.
 * @returns {*} Jsx which will be rendered.
 */
export default function PaymentSuccessPage() {

    const count = 200;
    let defaults = {
        origin: {y: 0.7}
    };

    function fire(particleRatio, opts) {
        confetti(Object.assign({}, defaults, opts, {
            particleCount: Math.floor(count * particleRatio)
        }));
    }

    fire(0.25, {
        spread: 26,
        startVelocity: 55,
    });
    fire(0.2, {
        spread: 60,
    });
    fire(0.35, {
        spread: 100,
        decay: 0.91,
    });
    fire(0.1, {
        spread: 120,
        startVelocity: 25,
        decay: 0.92,
    });
    fire(0.1, {
        spread: 120,
        startVelocity: 45,
    });

    const duration = 15 * 1000;
    const animationEnd = Date.now() + duration;
    defaults = {startVelocity: 30, spread: 360, ticks: 60, zIndex: 0};

    function randomInRange(min, max) {
        return Math.random() * (max - min) + min;
    }

    let interval = setInterval(function () {
        let timeLeft = animationEnd - Date.now();

        if (timeLeft <= 0) {
            return clearInterval(interval);
        }

        let particleCount = 50 * (timeLeft / duration);
        confetti(Object.assign({}, defaults, {
            particleCount,
            origin: {x: randomInRange(0.1, 0.3), y: Math.random() - 0.2}
        }));
        confetti(Object.assign({}, defaults, {
            particleCount,
            origin: {x: randomInRange(0.7, 0.9), y: Math.random() - 0.2}
        }));
    }, 250);

    return (
        <div className={"d-flex vh-100 align-items-center justify-content-center text-center"}>

            <div className={"col-md-4 my-auto"}>
                <Animated animationIn={"fadeInUp"}>
                    <h1>Gelukt!</h1>
                    <h5>Je rijles is geboekt! Binnen een paar minuten ontvang je een bevestigingsmailtje van
                        Rijlesplanner.
                        Veel
                        plezier! </h5>
                </Animated>
            </div>
        </div>
    );
}