import * as React from "react";
import Form from "react-bootstrap/Form";
import {Button} from "react-bootstrap";
import GoogleAutocompleteInput from "./GoogleAutocompleteInput";
import {Link} from "react-router-dom";
import {useState} from "react";

/**
 * This class will be rendering the address field on the main page, it uses the GoogleAutoCompleteInput file for
 * the actual input field to make sure that it has autocomplete.
 */
export default function LocationField() {
    const [btnDisabled, setBtnDisabled] = useState(true);
    const [address, setAddress] = useState("");
    const [currentSelected, setCurrentSelected] = useState("");

    const changeAddress = (data) => {
        if (!data || !data.label) {
            setBtnDisabled(true);
            setAddress(null);
            setCurrentSelected("");
            return;
        }
        setBtnDisabled(false);
        setAddress(data.label);
        setCurrentSelected({
            label: data.label,
            value: data.label
        });
    };

    return (
        <div className="my-4">
            <Form>
                <Form.Group>
                    <GoogleAutocompleteInput changeAddress={changeAddress} currentSelected={currentSelected}/>
                </Form.Group>
                <div className="d-flex justify-content-center w-100">
                    <Link to={"/proeflessen/" + address}>
                        <Button variant="dation" type="submit" className="px-5" id="BeschikbaarheidBtn"
                                disabled={btnDisabled}>
                            Toon beschikbaarheid
                        </Button>
                    </Link>
                </div>
            </Form>
        </div>
    );
}