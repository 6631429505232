import * as React from "react";
import {discountPrice, normalPrice} from "../config";
import {browserHistory} from "../App";

export default function OrderOverview(props) {
    let school = null;
    let year = null;
    let month = null;
    let day = null;

    let startTime = null;
    let endTime = null;
    let first = null;

    if (props.location.state) {
        school = props.location.state.school;
        year = parseInt(props.location.state.year);
        month = parseInt(props.location.state.month);
        day = props.location.state.monthDay.replace(/\u200E/g, '');

        startTime = props.location.state.startTime;
        endTime = props.location.state.endTime;
        first = props.location.state.first;
    } else {
        browserHistory.push("/");
    }
    let price;
    if (first) {
        price = (
            <span>
                <s className={"text-danger"}>{normalPrice}</s> {discountPrice} <span
                className={"text-muted font-size-small"}>(10% korting)</span>
            </span>
        );
    } else {
        price = normalPrice;
    }

    const dateFormatted = new Intl.DateTimeFormat("nl-NL", {
        month: 'long',
        day: '2-digit',
        year: 'numeric'
    }).format(new Date(Date.UTC(year, month, day)));
    if (school && dateFormatted && startTime && endTime) {
        return <section id={"infoSection"} className={"my-3 d-flex flex-column align-items-center"}>
            <p className={""}>
                <span className={"font-weight-bold"}>Proefles bij: </span>{school} <br/>
                <span className={"font-weight-bold"}>Datum: </span>{dateFormatted} <br/>
                <span className={"font-weight-bold"}>Tijd: </span>{startTime}:00 - {endTime}:00 <br/>
                <span className={"font-weight-bold"}>Prijs </span>{price} <br/>
            </p>
        </section>;
    } else {
        browserHistory.push("/");
    }

}