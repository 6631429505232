import React from "react";

/**
 * The page to which the user is redirected when the payment has failed.
 * @returns {*} Jsx which will be rendered.
 */
export default function PaymentStartedPage() {
    return (
        <div className={"d-flex vh-100 align-items-center justify-content-center text-center"}>
            <div className={"col-md-4 my-auto"}>
                <h1>Betaling gestart</h1>
                <h5>De betaling wordt geopend in een nieuw tabblad.</h5>
                <p>Je kunt deze pagina nu sluiten, zodra de betaling geslaagd is word je automatisch teruggestuurd naar de rijlesplanner website.</p>
            </div>
        </div>
    );
}